/* eslint-disable */
import React, { PureComponent } from 'react';
import Helmet from 'react-helmet';
import { Layout } from 'containers';
import { Banner } from 'components';
import { Link } from 'gatsby';
import { BASE_URL } from 'config';

class Feedback extends PureComponent {
  componentDidMount() {
  }

  render() {
    return (
      <Layout page="feed-back">
        <iframe src="https://forms.office.com/pages/responsepage.aspx?id=_rbR8QcgukytuzTFvzkY9y5-ch5HzrNDk-_FLW6xhsRUMEZZRTFMVDFMRUZGWVE3SVk3NFBaOUJMQi4u&embed=true" 
          style={{border: 'none', maxWidth: '100%', maxHeight: '100vh', minHeight: '320px', minWidth: '320px', height:'100vh'}}> 
        </iframe>
      </Layout>
    );
  }
}

export default Feedback;
